import React, {useEffect} from 'react';
import generatePostCard from '../services/PostCardGenerator';

interface ProgressBarProps {
    imageBase64: string | undefined;
    sportsId: number | null;
    generatedPostCard: (postCard: string) => void;
    active: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({imageBase64, sportsId, generatedPostCard, active}) => {
    // Function to handle the progress bar
    const handleProgressBar = () => {
        // Get the progress bar element
        const progressBar = document.querySelector('#bar02 .indicator') as HTMLElement;
        const progressText = document.querySelector('#bar02 .progress') as HTMLElement;

        if (progressBar && progressText) {
            // Get the current width of the progress bar
            let currentProgress = parseFloat(progressBar.style.width);

            // Increment the progress by 10%
            let newProgress = currentProgress + 1;

            // Ensure the progress doesn't exceed 100%
            if (newProgress > 100) {
                newProgress = 0;
            }

            // Update the progress bar width and the progress text
            progressBar.style.width = `${newProgress}%`;
            progressText.textContent = `${newProgress}%`;
        }
    }

    // Use useEffect to start the progress when the component mounts
    useEffect(() => {
        if (active) {

            const postCardData = {
                id: sportsId!, // Replace with actual sport number
                base64_image: imageBase64, // Replace with actual base64 image data
            };

            // Call the service function to generate the post card
            generatePostCard(postCardData)
                .then(data => {
                    console.log('Post card generated:', data);
                    generatedPostCard(data);
                })
                .catch(error => {
                    console.error('Error in post card generation:', error);
                });

            const interval = setInterval(handleProgressBar, 100);

            // Cleanup interval on component unmount
            return () => clearInterval(interval);
        }
    }, [active]); // Empty dependency array to run effect once when component mounts

    return (
        <>
            <div id="bar02" className="loader-bar">
                <div className="detail">
                    <div className="name">Processing</div>
                    <div className="detail-right">
                        <div className="progress">10%</div>
                    </div>
                </div>
                <div className="loader-bar--progress">
                    <div className="indicator" style={{width: "10%"}}></div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
