import React, {useEffect, useRef, useState} from 'react';
import ProgressBar from "../components/ProgressBar";
import WebcamComponent from "../components/PostCardWebcam/WebcamComponent";
import {Link} from "react-router-dom";
import sendEmail from "../services/PostCardEmail";
import './FullStepper.css';


const FullStepper: React.FC = () => {

    const GREETING_MESSAGE = `
    Hello and warm greetings from Lucerne! <br/> <br/>
    As you embarked on an extraordinary journey at the Swisscom Business Days,
    we wanted to add a special touch to your experience. <br/> Enclosed within this message, 
    you will discover your very own personalized AI Selfie, crafted by our AI Selfie app hosted on our Swiss AI Platform. <br/>
    Enjoy and have an incredible day! <br/> <br/>
    Best wishes, <br/>
    Your Swisscom GenAI team
    `;

    const [sportsId, setSportsId] = useState<number | null>(null);
    const [webcamActive, setWebcamActive] = useState<boolean>(false);
    const [screenshot, setScreenshot] = useState<string | undefined>(undefined);
    const [isProgressActive, setIsProgressActive] = useState(false);
    const [email, setEmail] = useState<string>("");
    const [generatedPostCard, setGeneratedPostCard] = useState<string | undefined>(undefined);
    const [emailSend, setEmailSend] = useState<boolean>(false);


    useEffect(() => {
        // Center the SDX Progress
        const intervalId = setInterval(() => {
            const progressBar = document
                .querySelector("#root > div > sdx-progress-full")
                ?.shadowRoot?.querySelector("nav > sdx-scroll-view > div") as HTMLElement;

            if (progressBar) {
                progressBar.style.justifyContent = "center"; // Center the progress bar
                clearInterval(intervalId);
            }
        }, 50);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleCardClick = (id: number) => {
        setSportsId(id);
    };

    const handleScreenshot = (newScreenshot: string | undefined) => {
        setScreenshot(newScreenshot);
    };

    const activateProgressBarOnClickNext = () => {
        setIsProgressActive(true);
        document.querySelectorAll('sdx-progress-full')[0].nextStep();
    };

    const handleGeneratedPostCard = (postCard: string) => {
        setGeneratedPostCard(postCard);
        setIsProgressActive(false);
        document.querySelectorAll('sdx-progress-full')[0].nextStep();
    }

    const handleEmail = (event: any) => {
        setEmail(event.target.value);
    }

    const sendEmailPostcard = async () => {
        if (!email || !generatedPostCard) {
            throw new Error("Missing one or more of the required fields: email, generated postcard, postcard back image");
        }
        let text_with_image = `
                <!DOCTYPE html>
                <html lang="en">
                <body>
                <p>${GREETING_MESSAGE}</p>
                <img src="data:image/png;base64,${generatedPostCard}" alt="Postcard">
                </body>
                </html>
            `
        text_with_image = btoa(text_with_image);
        try {
            const response = await sendEmail({
                to: email,
                // text: GREETING_MESSAGE,
                html: text_with_image,
                imagePostcardFront: generatedPostCard,
            });
            console.log("Email sent successfully", response);
            setEmailSend(true);
        } catch (error) {
            console.error("Failed to send email:", error);
        }
    }


    return (
        <div className="container"> {/* .container is an SDX class */}
            <div className="margin-v-3">
                <div className="row">
                    <div className="col-xs-1">
                        <div className="myImage myAnimation"></div>
                    </div>
                    <div className="col-xs-11">
                        <h1>Swisscom Business Days</h1>
                    </div>
                </div>
            </div>


            <sdx-progress-full
                sr-hint="Your current progress in completing your purchase"
                value={1}
                theme="responsive"
            >
                <sdx-progress-full-step label="Select Theme">
                    <div className="row row--gutters">
                        <div className="col-lg-4">
                            <sdx-card label="Rafting" className="hydrated" onClick={() => handleCardClick(0)}
                                      background={sportsId === 0 ? "grey" : undefined}>
                                <div className="sdx-card-image">
                                    <img src="/img/card/rafting.png" alt="rafting" className="card-image"/>
                                </div>
                            </sdx-card>
                        </div>
                        <div className="col-lg-4">
                            <sdx-card label="Climbing" className="hydrated" onClick={() => handleCardClick(1)}
                                      background={sportsId === 1 ? "grey" : undefined}>
                                <div className="sdx-card-image">
                                    <img src="/img/card/climbing_3.png" alt="climbing" className="card-image"/>
                                </div>
                            </sdx-card>
                        </div>
                        <div className="col-lg-4">
                            <sdx-card label="Jumping" className="hydrated" onClick={() => handleCardClick(2)}
                                      background={sportsId === 2 ? "grey" : undefined}>
                                <div className="sdx-card-image">
                                    <img src="/img/card/bungee-jumping.png" alt="bungee-jumping"
                                         className="card-image"/>
                                </div>
                            </sdx-card>
                        </div>
                        <div className="col-xs-12">
                            <sdx-button-group layout="responsive-center">
                                <sdx-button label="Next"
                                            disabled={sportsId === null}
                                            onClick={() => {
                                                setWebcamActive(true);
                                                document.querySelectorAll('sdx-progress-full')[0].nextStep();
                                            }}></sdx-button>
                            </sdx-button-group>
                        </div>
                    </div>
                </sdx-progress-full-step>


                <sdx-progress-full-step label="Take Picture">
                    <div className="row row--gutters">
                        <div className="col-xs-12">
                            <WebcamComponent
                                screenshot={screenshot}
                                onScreenshot={handleScreenshot}
                                disable={!webcamActive}
                            ></WebcamComponent>
                        </div>

                        <div className="col-xs-12">
                            <sdx-button-group layout="responsive-center">
                                <sdx-button label="Next"
                                            disabled={!screenshot}
                                            onClick={() => {
                                                document.querySelectorAll('sdx-progress-full')[0].nextStep();
                                                setWebcamActive(false);
                                                activateProgressBarOnClickNext();
                                            }}
                                >
                                </sdx-button>
                                <sdx-button label="Back"
                                            theme="secondary"
                                            onClick={() => {
                                                document.querySelectorAll('sdx-progress-full')[0].previousStep();
                                                setWebcamActive(false);
                                            }}
                                >
                                </sdx-button>
                            </sdx-button-group>
                        </div>
                    </div>
                </sdx-progress-full-step>


                <sdx-progress-full-step label="Generate">
                    <div className="row flex-items-xs-middle">
                        <ProgressBar
                            imageBase64={screenshot}
                            sportsId={sportsId}
                            generatedPostCard={handleGeneratedPostCard}
                            active={isProgressActive}
                        />
                        <div className="col margin-v-2">
                            <h2 style={{textAlign: "center", textShadow: "2px 4px 4px rgb(0 0 0 / 0.5)"}}>Please wait.
                                We are generating your postcard.</h2>
                        </div>
                    </div>
                </sdx-progress-full-step>


                <sdx-progress-full-step label="Provide E-Mail & AI Selfie sent">
                    <h1 style={{textAlign: "center"}}>Your AI Selfie is ready!</h1>
                    <div className="row flex-items-xs-center">
                        {generatedPostCard && <img src={`data:image/png;base64,${generatedPostCard}`}
                                                   alt="Postcard"/>} {/* Show generated postcard PNG */}
                    </div>
                    {!emailSend ? (
                        <>
                            <div className="margin-v-4">
                                <h2 style={{textAlign: "center"}}>Type in an email address to send your AI Selfie</h2>
                            </div>
                            <div className="sdx-input-textarea">
                                <sdx-input
                                    label="Enter your email"
                                    placeholder="Please write your input here"
                                    type="email"
                                    onInput={handleEmail}
                                ></sdx-input>
                            </div>
                            <div className="padding-v-4">
                                <sdx-button-group layout="responsive-center">
                                    <sdx-button
                                        theme="confirm"
                                        label="Send"
                                        onClick={sendEmailPostcard}
                                    ></sdx-button>
                                </sdx-button-group>
                            </div>
                            <div className="padding-bottom-4">
                                <sdx-button-group layout="responsive-center">
                                    <Link to="/">
                                        <sdx-button label="Go to Discovery Page"></sdx-button>
                                    </Link>
                                </sdx-button-group>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col text-align-center">
                                <div className="padding-v-4">
                                    <h2 style={{textAlign: "center"}}>Your AI Selfie was sent. <br/>
                                        Enjoy your stay at the Swisscom Business Days
                                    </h2>
                                </div>
                                <img
                                    src="/img/email/bird.png"
                                    alt="Email sent confirmation"
                                />
                            </div>
                            <div className="margin-bottom-4">
                                <sdx-button-group layout="responsive-center">
                                    <Link to="/">
                                        <sdx-button label="Go to Start Page"></sdx-button>
                                    </Link>
                                </sdx-button-group>
                            </div>
                        </>
                    )}
                </sdx-progress-full-step>
            </sdx-progress-full>
        </div>
    );
}

export default FullStepper;
