import axios from 'axios';

// Define the payload type
interface PostCardEmailData {
    to: string | null;
    text?: string | null;
    html?: string | null;
    imagePostcardFront: string | undefined;
}

// Service function to call the backend API
const sendEmail = async (data: PostCardEmailData) => {
    if (!data) {
        throw new Error('No image provided');
    }
    try {
        axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:3001';
        console.log(data);
        if (!data.html) {
            const response = await axios.post('/send-email', {
                to: data.to,
                text: data.text,
                imagePostcardFront: data.imagePostcardFront,
            });
            return response.data;
        } else {
            const response = await axios.post('/send-email', {
                to: data.to,
                html: data.html,
                imagePostcardFront: data.imagePostcardFront,
            });
            return response.data;
        }
    } catch (error) {
        console.error('Error generating post card:', error);
        throw error;
    }
};

export default sendEmail;
