import React, {useEffect, useState, useRef} from 'react';
import Webcam from 'react-webcam';
import './WebcamComponent.css';

interface WebcamComponentProps {
    screenshot: string | undefined;
    onScreenshot: (screenshot: string | undefined) => void;
    disable?: boolean;
}

const WebcamComponent: React.FC<WebcamComponentProps> = ({screenshot, onScreenshot, disable = false}) => {
    const [countdown, setCountdown] = useState<number | null>(null);
    const [countdownDate, setCountdownDate] = useState<string | undefined>(undefined);
    const webcamRef = useRef<Webcam>(null); // UseRef to access webcam instance

    const videoConstraints = {
        width: 1024,
        height: 1024,
        facingMode: "user"
        // facingMode: { exact: "environment" } // Use this for rear camera
    };

    // Function to start the countdown
    const startCountdown = () => {
        setCountdown(5);  // Start countdown at 5
        setCountdownDate(dateToCountdown()); // Set countdown end time
    };

    // Function to generate countdown end time
    const dateToCountdown = () => {
        let date = new Date();
        date.setSeconds(date.getSeconds() + 5);
        return date.toISOString();
    };

    // Effect to handle the countdown and take screenshot after countdown reaches 0
    useEffect(() => {
        if (countdown === null) return;

        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000); // Decrease countdown every second
            return () => clearTimeout(timer);  // Clean up the timer on unmount
        }

        if (countdown === 0) {
            const imageSrc = webcamRef.current?.getScreenshot(); // Capture screenshot after countdown
            onScreenshot(imageSrc || undefined); // Pass screenshot to parent via prop
            setCountdown(null); // Reset countdown
        }
    }, [countdown, onScreenshot]);

    return (
        <>
            {!screenshot && !disable ? (
                <>
                    <div className="webcam-container">
                        <Webcam
                            ref={webcamRef}
                            mirrored
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            className="webcam-video" // Add className to the Webcam component
                        />
                        <div className="silhouette-overlay">
                            <img
                                src="/img/Silhouette.svg"
                                alt="Silhouette"
                                className="silhouette-image"
                            />
                        </div>
                    </div>
                    <div className="margin-v-2">
                        {!countdown && (
                            <sdx-button-group layout="responsive-center">
                                <sdx-button
                                    theme="confirm"
                                    label="Take Screenshot"
                                    onClick={startCountdown}
                                    disabled={disable}
                                />
                            </sdx-button-group>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className="screenshot-preview">
                        <h3>Screenshot Preview</h3>
                        <img src={screenshot} alt="Screenshot" />
                    </div>
                    <div className="margin-v-2">
                        <sdx-button-group layout="responsive-center">
                            <sdx-button
                                theme="confirm"
                                label="Retake Screenshot"
                                onClick={() => onScreenshot(undefined)}
                            />
                        </sdx-button-group>
                    </div>
                </>
            )}

            {countdown !== null && (
                <sdx-button-group layout="responsive-center">
                    <sdx-countdown
                        mask="MM-SS"
                        date={countdownDate}
                        size="large"
                    ></sdx-countdown>
                </sdx-button-group>
            )}
        </>
    );};

export default WebcamComponent;
