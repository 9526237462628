import React from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';
import FullStepper from "./pages/FullStepper";
import LandingPage from "./pages/LandingPage";

const App: React.FC = () => {

    return (
        <>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/steppers" element={<FullStepper/>}/>
            </Routes>
        </>
    );
}

export default App;
